<template>
    <div class="ele-page">
        <page-title :title="'电器管理'">维护电器信息</page-title>
        <div class="page-content">
            <div class="page-left">
                <el-button size="small" type="success" style="margin-bottom:10px;" @click="addClassDialog=true">新增分类
                </el-button>
                <el-tree ref="classTree" :data="classList" show-checkbox node-key="id" :props="{label:'className' }" default-expand-all
                    :expand-on-click-node="false" @node-click="handleNodeClick">
                    <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;"
                        slot-scope="{ node, data }">
                        <span>{{ node.label}}</span>
                        <span v-if="node.id!=1">
                            <el-button type="text" size="mini" @click.stop="() => editClass(data)">
                                修改
                            </el-button>
                            <el-button type="text" size="mini" @click.stop="() => delClass(data)">
                                删除
                            </el-button>
                        </span>
                    </div>
                </el-tree>
            </div>
            <div class="page-right">
                <div class="sel-area">
                    <el-input size="small" placeholder="请输入电器名称检索" v-model="selCon.eleName" clearable
                        style="width:280px;margin-right:10px;"></el-input>
                    <el-button icon="el-icon-search" type="primary" size="small" @click="handleSel">查询</el-button>
                </div>
                <el-button size="small" type="success" style="margin-bottom:10px;" @click="addInfoDialog=true">新增电器
                </el-button>
                <el-table :data="tableData" size="mini" border style="width: 100%">
                    <el-table-column type="index" label="序号" width="50" align="center">
                    </el-table-column>
                    <el-table-column prop="eleName" label="电器名称" width="280" align="center">
                    </el-table-column>
                    <el-table-column label="标签" width="240" align="center">
                        <template slot-scope="scope">
                            <el-tag v-for="item in strSplit(scope.row.eleTags)" :key="item" size="mini" effect="dark"
                                style="margin-right:3px;" :type="tagsArr[Math.floor(Math.random()*5)]">{{item}}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" align="center">
                    </el-table-column>
                    <el-table-column prop="eleClassName" label="所属分类" align="center">
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建日期" width="160" align="center">
                    </el-table-column>
                    <el-table-column label="操作" header-align="center" min-width="120" width="160" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="warning" @click="editInfo(scope.row)">修改</el-button>
                            <el-button size="mini" type="danger" @click="delInfo(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background style="margin-top:20px;text-align:right;" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page.sync="page.current" :page-sizes="[10, 50, 100, 200]"
                    :page-size="page.pageSize" layout="total,sizes, prev, pager, next" :total="page.total">
                </el-pagination>
            </div>
        </div>
        <el-dialog title="新增分类" :visible.sync="addClassDialog" custom-class="my-dialog-style" @close="resetForm">
            <el-form ref="classForm" :model="classForm" size="small" label-width="100px" label-position="right">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="分类名称：" prop="className">
                            <el-input v-model="classForm.className" placeholder="请输入分类名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="分类编码:" prop="classCode">
                            <el-input v-model="classForm.classCode" placeholder="请输入编码"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="说明：" prop="remark">
                            <el-input v-model="classForm.remark" placeholder="分类说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addClassDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="classSaveOrEdit" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="新增电器" :visible.sync="addInfoDialog" custom-class="my-dialog-style" @close="resetForm">
            <el-form ref="infoForm" :model="infoForm" size="small" label-width="100px" label-position="right">
                <el-row :gutter="10">
                    <el-col :span="12">
                        <el-form-item label="电器名称：" prop="eleName">
                            <el-input v-model="infoForm.eleName" placeholder="请输入分类名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="所属分类:" prop="eleClassId">
                            <el-select v-model="infoForm.eleClassId" style="width:100%" placeholder="请选择电器分类">
                                <el-option v-for="item in classList[0].children" :key="item.id" :label="item.className"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="标签：" prop="eleTags">
                            <el-tag :key="tag" v-for="tag in eleTags" closable :disable-transitions="false" size="small"
                                effect="dark" @close="handleClose(tag)">
                                {{tag}}
                            </el-tag>
                            <el-input class="input-new-tag" v-if="tagVisible" v-model="tagValue" ref="saveTagInput"
                                size="mini" @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
                            </el-input>
                            <el-button v-else class="button-new-tag" size="mini" @click="showInputTag">新增标签</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="10">
                    <el-col :span="24">
                        <el-form-item label="说明：" prop="remark">
                            <el-input v-model="infoForm.remark" placeholder="分类说明"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addInfoDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="infoSaveOrEdit" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "EleApp",
    data() {
        return {
            classList: [{
                id: 0,
                className: '全部分类',
                children: []
            }],
            tableData: [],
            page:{
                current:1,
                pageSize:10,
                total:0,
            },
            addClassDialog: false,
            addInfoDialog: false,
            classForm: {},
            infoForm: {},
            selCon: {
                eleName: ""
            },
            eleTags: [],
            tagVisible: false,
            tagValue: '',
            tagsArr: ['', 'success', 'info', 'danger', 'warning']
        }
    },
    computed: {
        strSplit() {
            return (str) => str ? str.split(",") : "";
        }
    },
    created() {
        this.initClass();
        this.initPage();
    },
    methods: {
        /**分类点击筛选 */
        handleNodeClick(data) {
            const that = this;
            that.$refs.classTree.setCheckedKeys([]);
            var checkedArr = [];
            checkedArr.push(data);
            that.$refs.classTree.setCheckedNodes(checkedArr);
            if (data.id != 0) {
                that.page.eleClassId = data.id;
                that.initPage();
            } else {
                that.page.eleClassId = null;
                that.initPage();
            }
        },
        /**电器检索 */
        handleSel() {
            const that = this;
            if(that.selCon.eleName){
                that.page.eleName = that.selCon.eleName;
            }else{
                that.page.eleName = null;
            }
            that.initPage();
        },
        /**初始化数据列表 */
        initPage() {
            const that = this;
            let _params = that.page;
            that.request.get("ele/info/page",_params).then(res => {
                if(res.code===200){
                    that.tableData = res.data.records;
                    that.page.total = res.data.total;
                }
                
            });
        },
        /**删除电器信息 */
        delInfo(id) {
            const that = this;
            that.$confirm('此操作将永久删除该电器, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("ele/info/delete", { id: id }).then(res => {
                    if (res.code === 200) {
                        that.initPage();
                    }
                });
            }).catch({});
        },
        /**修改电器表格数据 */
        editInfo(row) {
            this.infoForm = JSON.parse(JSON.stringify(row));
            this.infoForm.eleTags ? (this.eleTags = this.infoForm.eleTags.split(",")) : [];
            this.addInfoDialog = true;
        },
        /**电器新增表单保存 */
        infoSaveOrEdit() {
            const that = this;
            that.infoForm.eleTags = that.eleTags.join(",");
            that.classList[0].children.find(item => {
                if (item.id === that.infoForm.eleClassId) {
                    that.infoForm.eleClassName = item.className;
                }
            });
            that.request.post("ele/info/saveOrEdit", that.infoForm).then(res => {
                if (res.code === 200) {
                    that.addInfoDialog = false;
                    that.initPage();
                    that.$refs['infoForm'].resetFields();
                }
            });
        },
        /**初始化分类信息 */
        initClass() {
            const that = this;
            that.request.get("ele/class/list",{type:'ele'}).then(res => {
                that.classList[0].children = res.data;
            });
        },
        /**编辑分类信息 */
        editClass(data) {
            const that = this;
            that.classForm = JSON.parse(JSON.stringify(data));
            that.addClassDialog = true;
        },
        /**删除分类信息 */
        delClass(data) {
            const that = this;
            that.$confirm('此操作将永久删除该分类及该分类下的所有电器, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("ele/class/delete", { id: data.id }).then(res => {
                    if (res.code === 200) {
                        that.initClass();
                        that.initPage();
                    }
                });
            }).catch({});
        },
        /**电器分类信息维护 */
        classSaveOrEdit() {
            const that = this;
            that.classForm.type='ele';
            that.request.post("ele/class/saveOrEdit", that.classForm).then(res => {
                if (res.code === 200) {
                    that.addClassDialog = false;
                    that.initClass();
                    that.$refs['classForm'].resetFields();
                }
            });
        },
        /**标签处理相关：移除标签 */
        handleClose(tag) {
            this.eleTags.splice(this.eleTags.indexOf(tag), 1);
        },
        /**标签处理相关：显示标签输入框 */
        showInputTag() {
            this.tagVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },
        /**标签处理相关：新增标签 */
        handleInputConfirm() {
            let tagValue = this.tagValue;
            if (tagValue) {
                this.eleTags.push(tagValue);
            }
            this.tagVisible = false;
            this.tagValue = '';
        },
        /**重置表单 */
        resetForm() {
            if (this.$refs['classForm']) {
                this.$refs['classForm'].resetFields();
                this.classForm.id = null;
            } else if (this.$refs['infoForm']) {
                this.$refs['infoForm'].resetFields();
                this.infoForm.id = null;
            }
        },
        /**页面条数变化事件 */
        handleSizeChange(e) {
            this.page.pageSize = e;
            this.page.current = 1;
            this.initPage();
        },
        /**当前页变化 */
        handleCurrentChange() {
            this.initPage();
        }
    }
}
</script>
<style lang="less" scoped>
.ele-page {
    .page-content {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .page-left {
            width: 320px;
            min-height: 600px;
            margin-right: 20px;
            border-right: 1px solid #d9d9d9;
        }
        .page-right {
            width: calc(100% - 350px);

            .sel-area {
                margin-bottom: 10px;
            }
        }
    }

    /deep/ .el-tree-node__content {
        height: 36px;
    }

    .el-tag+.el-tag {
        margin-left: 10px;
    }

    .button-new-tag {
        margin-left: 10px;
    }

    .input-new-tag {
        width: 90px;
        margin-left: 10px;
        vertical-align: bottom;
    }
}
</style>